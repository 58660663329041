import styled from "styled-components";
import HeroBg from "assets/image/hero-bg.png";
import {Paragraph, TitleXL} from "components/Typography/Typography";
import {Link} from "gatsby";
import {resolveContainerBackground} from "../Section/styles";

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    background-image: ${({bg}) => (bg ? `` : `url(${HeroBg})`)};
    background-color: ${({color, theme}) =>
            resolveContainerBackground(color, theme)};
    display: flex;
    align-items: center;

    &.scrolled {
        opacity: 0;
        z-index: -1;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        position: relative;
        padding: 100px 0 82px 0;
    }

`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;

    &.text {
        align-items: flex-end;
        margin-top: 270px;
    }

    &:first-of-type {
        width: 60%;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        &.text {
            margin-top: 154px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100%;
        text-align: center;

        &:first-of-type {
            width: 100%;
        }

        &.text {
            margin-top: 12px;
        }
    }
`;

export const LogoWrapper = styled(Link)`
    width: 272px;
    height: 82px;
    position: absolute;
    top: -40px;
    z-index: 2;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 210px;
        height: 63px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        width: 182px;
        height: 54px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 124px;
        height: 46px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        left: 50%;
        transform: translateX(-50%);
        top: -48px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) and (orientation: landscape) {
        width: 122px;
        height: 36px;
        top: -36px;
    }
`;

export const Manufacturer = styled.div`
    width: 200px;
    height: 50px;
    margin-bottom: 36px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        height: 40px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        height: 30px;
        width: 150px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin: 12px auto 36px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) and (orientation: landscape) {
        margin: 12px auto 36px;
        width: 100px;
        height: 20px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_big}) and (orientation: landscape) {
        margin: 0 auto 24px;
    }
`;

export const Logo = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
`;

export const Title = styled(TitleXL)`
    line-height: 1.3;
    padding-top: 80px;

    span {
        color: ${({theme}) => theme.colors.gray};
    }

    color: ${({color, theme}) => color === "black" ? theme.colors.white : theme.colors.black};
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding-top: 62px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        padding-top: 54px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        padding-top: 46px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding-top: 24px;
        line-height: 1.5;
        max-width: 100%;
        margin: 0 auto;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: ${({theme}) => theme.fonts.m.tablet};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) and (orientation: landscape) {
        padding-top: 12px;
    }
`;

export const Span = styled.span``;

export const StyledParagraph = styled(Paragraph)`
    width: 85%;
    position: relative;
    line-height: 1.2;
    color: ${({color, theme}) => color === "black" ? theme.colors.white : theme.colors.black};

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -50px;
        width: 100%;
        height: 4px;
        background-color: ${({color, theme}) => color === "black" ? theme.colors.white : theme.colors.black};
    }
    
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        width: 100%;

        &::after {
            height: 3px;
            bottom: -40px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        &::after {
            bottom: -24px;
            height: 2px;
        }

    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100%;
        line-height: 1.5;
        &::after {
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) and (orientation: landscape) {
        font-size: 12px;
    }

`;

export const BgWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .gatsby-image-wrapper {
        max-width: 100% !important;
        max-height: 100% !important;
    }
`;
