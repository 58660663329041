import React, {useEffect, useRef, useState} from "react";
import {BgWrapper, Column, Container, Content, Logo, LogoWrapper, Manufacturer, Span, StyledParagraph, Title} from "./styles";
import {SectionWrapper} from "shared/Wrappers/wrappers";
import Wumax from "assets/svg/wumax-logo_kolor2.svg";
import WumaxWhite from "assets/svg/wumax-logo_white.svg";
import Image from "gatsby-image";
import {useDispatch, useSelector} from "react-redux";
import {heroToggle} from "../../store/menuColor";
import {useScrollPosition} from "../../shared/Hooks/useScrollPosition";

const Hero = ({data, color}) => {
    const [scrolled, setScrolled] = useState(false);
    const dispatch = useDispatch();
    const heroRef = useRef(null);
    const {locale} = useSelector(state => state.modals);
    let window_width = 0;
    const isBrowser = typeof window !== `undefined`;
    if (isBrowser) {
      window_width = window.innerWidth;
    }
    useScrollPosition(({currPos}) => {
      if (currPos.y < -window.innerHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });

    useEffect(() => {

      const scrollHandler = () => {
        if (color === 'black') {
          if (window) {
            if (window.scrollY < heroRef.current.offsetHeight) {
              dispatch(heroToggle(true))
            } else {
              dispatch(heroToggle(false))
            }
          }
        }
      };

      if (color === 'black') {
        dispatch(heroToggle(true))
      } else {
        dispatch(heroToggle(false));
      }
      if(window_width>1100){
        window.addEventListener("scroll", scrollHandler)
      }

      return () => {
        if(window_width>1100){
          window.removeEventListener("scroll", scrollHandler)}
        }
    }, [color, dispatch, window_width]);

    return (
        <Container bg={data.bg?.src} color={color} className={scrolled ? color === "black" ? 'scrolled black-hero' : 'scrolled' : color === "black" ? 'black-hero' : ''} ref={heroRef}>
          <SectionWrapper>
            <Content>
              <LogoWrapper to={locale === 'en' ? '/en' : '/'}>
                <Logo
                  src={color === "black" ? WumaxWhite : Wumax}
                  alt="Logo Wumax"
                  data-aos="fade-up"
                  className="disableAosMobile"
                />
              </LogoWrapper>
              <Column>
                <Title as="h1" color={color} data-aos="fade-up"     className="disableAosMobile">
                  {data.ogImage && (
                    <Manufacturer>
                      <Logo src={data.ogImage} alt=""/>
                    </Manufacturer>
                  )}
                  {data.title} <Span>{data.titleColor}</Span>
                </Title>
              </Column>
              <Column className="text">
                <StyledParagraph     className="disableAosMobile" data-aos="fade-up" data-aos-delay="100" color={color}>{data.subtitle}</StyledParagraph>
              </Column>
            </Content>
          </SectionWrapper>
          {data.bg && (
            <BgWrapper>
              <Image
                fluid={data.bg?.childImageSharp.fluid}
                style={{width: "100%", height: "100%"}}
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </BgWrapper>
          )}
        </Container>
    );

  }
;

export default Hero;
