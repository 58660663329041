import styled from "styled-components";
import {GlobalWrapper, SectionWrapper} from "shared/Wrappers/wrappers";
import {Paragraph, TitleXL} from "../Typography/Typography";

export const resolveContainerBackground = (color, theme) => {
  switch (color) {
    case "yellow":
      return theme.colors.yellow;
    case "black":
      return theme.colors.black;
    default:
      return theme.colors.white;
  }
}

export const Container = styled.section`
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    padding: 48px 0;
    background-color: ${({color, theme}) => resolveContainerBackground(color, theme)};

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background-color: ${({color, theme}) => resolveContainerBackground(color, theme)};
    }

    .rectangle {
        width: 2%;
        height: 100%;
        position: absolute;
        top: 20%;
        left: ${({reverse}) => (reverse ? "" : "0")};
        right: ${({reverse}) => (reverse ? "0" : "")};
        z-index: 3;
        overflow: hidden;

        .parallax-inner {
            width: 100%;
            height: 100%;
        }
    }
`;

export const StyledGlobalWrapper = styled(GlobalWrapper)`
    position: relative;
    z-index: 4;
`;

export const Rectangle = styled.div`
    height: 70%;
    width: 30px;
    background-color: ${({theme}) => theme.colors.yellow};

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        display: none;
    }
`;

export const BackgroundWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;

    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

export const StyledSectionWrapper = styled(SectionWrapper)`
    display: flex;
    flex-wrap: wrap;
    z-index: 3;
    flex-direction: ${({reverse}) => (reverse ? "row-reverse" : "")};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        justify-content: center;
    }
`;

export const Header = styled.header``;

export const Column = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
        align-items: ${({reverse}) => (reverse ? "flex-end" : "")};
        padding: ${({reverse}) => (reverse ? "0 0 0 48px" : "0 48px 0 0")};
    }

    &.gallery {
        padding-right: 48px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100%;
        text-align: center;
        align-items: center;
        padding-right: 0;

        &:first-child {
            align-items: center;
            padding-left: 0;
            padding-right: 0;
        }

        &.gallery {
            padding-right: 0;
        }
    }
`;

export const ImageWrapper = styled.div`
    width: 350px;
    height: 80px;
    position: relative;
    display: flex;
    justify-content: ${({reverse}) => (reverse ? "flex-end" : "")};

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }

    &.big {
        width: 70%;
        height: initial;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 270px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        width: 234px;
        &.big {
            width: 75%;
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 200px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-bottom: 36px;
        justify-content: center;
        height: 60px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) and (orientation: landscape) {
        &.big {
            width: 40%;
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_big}) and (orientation: portrait) {
        &.big {
            width: 70%;
        }
    }
`;

export const Title = styled(TitleXL)`
    margin-bottom: 64px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-bottom: 50px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        margin-bottom: 42px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        margin-bottom: 36px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-bottom: 28px;
        font-size: ${({theme}) => theme.fonts.l.tablet};
    }
`;

export const StyledParagraph = styled(Paragraph)`
    &:first-of-type {
        margin-bottom: 24px;
    }

`;
